import React from "react"
import Logo from "../components/common/logo/Logo"
import Footer from "../components/footer/footer"
import { graphql } from "gatsby"
import ExtendedContent from "../components/extendedContent"
import ChangeLanguage from "../components/changeLanguage/ChangeLanguage"
import CookiesBar from "../components/cookiesBar/CookiesBar"
import SEO from "../components/seo"

const IndexPage = ({ data, pageContext }) => {
	const { entries,  logo, socialMedia, modals, translations, footer, pojedyncze, aktualnosciWszystkie, cookies, seoPojedyncze, seoDefault } = data.craft
	const activeSite = pageContext.site
	const entry = pojedyncze[0].wybierzModul
	const translationsData = (translations && translations[0]) || []
	const logoData = (logo && logo[0]) || []
	const footerData = (footer && footer[0]) || []
	const socialMediaData = (socialMedia && socialMedia[0]) || []
	const modalData = (modals && modals[0]) || []
	const firstOtherLangPathArray = entries
	const seoData = seoPojedyncze.length > 0 ? seoPojedyncze[0] : ""
	const seoDefaultData = (seoDefault && seoDefault[0])|| ""

	return (
		<>
			<SEO seoData={seoData} seoDefault={seoDefaultData}/>
			<Logo logoData={logoData} activeSite={activeSite}/>
			<ChangeLanguage firstOtherLangPathArray={firstOtherLangPathArray} pageContext={pageContext}/>
			<ExtendedContent data={entry} modalData={modalData} translationsData={translationsData} pageContext={pageContext}
											 newsAll={aktualnosciWszystkie} activeSite={activeSite}/>
			<CookiesBar cookies={cookies}/>
			<Footer footerData={footerData} socialMediaData={socialMediaData} activeSite={activeSite}/>
		</>
	)
}

export default IndexPage

export const query = graphql`
    query($id: [Craft_QueryArgument], $site: [String], $firstOtherLangSite: [String], $limit: Int!, $offset: Int!) {
        craft {
            seoDefault:  globalSets(site: $site, handle: "seo") {
                ...seoDefault
            }
            seoPojedyncze: entries(section:  "pojedyncze", id: $id, site: $site) {
                ...seoPojedyncze
            }
            menu: entries(section: "menu", site: $site) {
                ...menu
            }
            entries(section: ["pojedyncze","produkty","aktualnosci"], site: $firstOtherLangSite) {
                slug
                id
            }
            pojedyncze: entries(section: "pojedyncze", id: $id, site: $site) {
                ... on Craft_pojedyncze_pojedyncze_Entry {
                    wybierzModul {
                        __typename
                        ...zobaczRowniez,
                        ...trescPodstrony,
                        ...banerZNadchodzacaAktualnoscia
                    }
                }
            }
            aktualnosciWszystkie: entries(section: "aktualnosci", site: $site, offset: $offset, limit: $limit) {
                ...aktualnosci
            }
            logo: globalSets(site: $site, handle: "logo") {
                ...logo
            }
            translations: globalSets(site: $site, handle: "tlumaczenie") {
                ...tlumaczenia
            }
            socialMedia: globalSets(site: $site, handle: "socialMedia") {
                ...socialMedia
            }
            modals: globalSets(site: $site, handle: "modale") {
                ...modale
            }
            footer: globalSets(site: $site, handle: "stopka") {
                ...stopka
            }
            cookies: globalSets(site: $site, handle: "cookies") {
                ...cookies
            }
        }
    }
`
